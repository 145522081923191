export default {
  breakpoints: ['40em', '52em', '64em'],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  colors: {
    text: '#fff',
    background: '#000',
    gray: '#999'
  },
  fonts: {
    sans: '"Libre Franklin", sans-serif',
  },
  fontSizes: [12, 18, 24, 32, 48, 64, 88],
  fontWeights: {
    regular: 400,
    medium: 600,
    black: 900,
  },
  lineHeights: {
    normal: 1.5,
    heading: 1.125,
  },
  text: {
    h1: {
      fontFamily: 'sans',
      lineHeight: 'heading',
      fontWeight: 'black',
      fontSize: ["20vw", "8vw"],
      mt: 4,
      mb: 4
    },
    h2: {
      fontFamily: 'sans',
      lineHeight: 'heading',
      fontWeight: 'black',
      fontSize: [4, 5],
      mt: 5,
      mb: 5
    },
    h3: {
      fontFamily: 'sans',
      lineHeight: 'heading',
      fontWeight: 'medium',
      fontSize: 3,
      mt: 4,
      mb: 3
    },
    body: {
      fontFamily: 'sans',
      lineHeight: 'normal',
      fontWeight: 'regular',
      fontSize: 1,
      mt: 2,
      mb: 2
    },
    link: {
      fontFamily: 'sans',
      lineHeight: 'normal',
      fontWeight: 'medium',
      fontSize: '1em',
      display: 'inline',
      mt: 2,
      mb: 2,
      "&:hover": { 
        bg: "text",
        color: 'background'
      }
    },
  },
  styles: {
    root: {
      fontFamily: 'sans',
      lineHeight: 'normal',
      fontWeight: 'regular',
      fontSize: 1
    },
    h1: {
      variant: 'text.h1',
    },
    h2: {
      variant: 'text.h2',
    },
    h3: {
      variant: 'text.h3',
    },
    h4: {
      variant: 'text.body',
      fontWeight: 'heavy',
      mt: 4
    },
    p: {
      variant: 'text.body'
    },
    a: {
      variant: 'text.link'
    },
    hr: {
      color: "text",
      borderColor: "text",
      borderTop: "none",
      borderBottom: "2px solid",
      my: 5
    }
  },
}